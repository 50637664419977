$block-whitespace: 1rem;
$block-whitespace--from-sm: 7rem;

.article-statistic-blocks {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;
    margin-left: -$block-whitespace / 2;
    margin-right: -$block-whitespace / 2;
    padding-left: 0;

    @include mq($from: md) {
        flex-wrap: nowrap;
        min-width: calc(100% + #{$block-whitespace--from-sm});
        margin-left: -$block-whitespace--from-sm / 2;
        margin-right: -$block-whitespace--from-sm / 2;
    }
}

.article-statistic-blocks__item {
    display: flex;
    width: 100%;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: flex-start;
    margin: $block-whitespace / 2;
    padding: $block-whitespace * 1.5;
    background-color: $secondary-background-color;
    border-radius: $border-radius;

    > * {
        max-width: 100%;

        &:last-child {
            margin-top: auto;
        }
    }

    h3 {
        font-size: 2em;

        @include mq($from: md) {
            font-size: 2.5em;
        }
    }

    small {
        width: 100%;
        max-width: 100%;
    }

    @include mq($from: md) {
        width: calc(33% - 2em);
    }
}
