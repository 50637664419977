.cta {
    position: relative;
    z-index: 1;
    display: block;
    width: calc(100% + #{$content-whitespace * 2});
    margin: 1em (-$content-whitespace);
    padding: 1.5em 0;
    overflow: hidden;
    text-align: center;

    @include mq($from: md) {
        padding-top: 3vw;
        padding-bottom: 3vw;
    }
}

.cta--primary {
    padding-top: 3em;
    padding-bottom: 3em;
    background-color: $primary-color;
    background-image: $primary-gradient;
    color: #fff;
    fill: #fff;

    @include mq($from: md) {
        padding-top: 6vw;
        padding-bottom: 6vw;
    }

    &:last-child {
        margin-bottom: -$content-whitespace;
    }

    ::selection {
        background-color: rgba(#fff, 0.25);
    }

    h1,
    h2,
    h3,
    h4 {
        color: #fff;
    }
}

.cta--card {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-radius: $border-radius;
}

.cta--flatten {
    margin-top: 0;
    margin-bottom: 0;
}

.cta__content {
    max-width: 75%;
    margin: 0 auto;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    > .container {
        @include mq($until: md) {
            width: 100%;
            max-width: 100%;
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }
    }

    .button:last-child {
        margin-right: 0;
    }

    h1,
    h2,
    h3 {
        + .button {
            margin-top: 0.75em;
        }
    }
}

.cta__content__title {
    margin-bottom: 1.25rem;
}

.cta__details {
    display: flex;
    align-items: center;
    text-align: left;

    @include mq($until: md) {
        flex-direction: column;
    }
}

.cta__details__image {
    position: relative;
    align-self: stretch;
    width: 90%;
    margin: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @include mq($from: md) {
        flex: 0 0 40%;
        margin-right: 5%;
    }
}

.cta__details__image--screenshot {
    top: 1px;
    max-width: 100%;
    height: auto;
    margin-bottom: -1.5em;
    margin-top: 0.75em;
    background-position: bottom center;

    @include mq($until: md) {
        order: 2;
        height: 420px;
        margin: -1.5vw auto -3vw;
    }

    .cta--primary & {
        margin-bottom: -3em;
        margin-top: 1.5em;

        @include mq($from: md) {
            margin-bottom: -6vw;
            margin-top: -3vw;
        }
    }
}

.cta__details__title {
    margin-bottom: 0.5em;

    @include mq($from: lg) {
        max-width: 100%;
    }
}

.cta__details__subtitle {
    margin-top: 2em;
    margin-bottom: 1em;
}

.cta__details__tips {
    margin-bottom: 1.75em;

    li {
        position: relative;
        padding-left: 1.5em;

        svg {
            position: absolute;
            left: 0;
            top: 0.2em;
        }
    }
}

.cta__icon {
    display: inline-block;
    width: 2.5em;
    height: 2.5em;
    margin: 0.5em 0;

    &:first-child {
        margin-top: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }

    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}
