.appstore {
    display: inline-block;
    width: 140px;
    height: 48px;
    margin-right: 0.5em;

    @include mq($until: md) {
        width: 120px;
        height: 41px;
    }

    &:last-child {
        margin-right: 0;
    }
}
