.button {
    position: relative;
    display: inline-block;
    margin: 0 0.25em 0.25em 0;
    padding: 0.8em 1.5em;
    border: none;
    border-radius: 2em;
    outline: none;
    color: #fff;
    fill: #fff;
    background-color: $primary-color;
    background-image: $primary-gradient;
    font-weight: normal;
    line-height: 1.5;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    transition: width 0.5s;

    &:hover,
    &:focus {
        color: #fff;
        fill: #fff;
        background-color: $primary-color-lighter;
        background-image: $primary-gradient-lighter;
    }

    &[disabled] {
        cursor: not-allowed;
    }

    &.is-loading {
        color: transparent !important;
        user-select: none;
        touch-action: none;
        -webkit-touch-callout: none;

        &:before,
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 1.5em;
            height: 1.5em;
            margin-top: -0.75em;
            margin-left: -0.75em;
            border: 2px solid #fff;
            border-radius: 50%;
        }

        &:before {
            opacity: 0.25;
        }

        &:after {
            border-left-color: transparent !important;
            border-right-color: transparent !important;
            border-bottom-color: transparent !important;
            animation: spin 1s linear infinite;
        }
    }

    ::selection {
        background-color: rgba(#fff, 0.25);
    }
}

.button--outline {
    border: 1px solid $primary-color;
    color: $primary-color;
    background: transparent;
    fill: $primary-color;

    &:hover,
    &:focus {
        color: #fff;
        background-color: $primary-color;
        background-image: $primary-gradient;
    }
}

.button--disabled {
    opacity: 0.35;
    cursor: default;
}

.button--flatten {
    margin: 0;
}

.button--background {
    background: $secondary-background-color;
    color: $text-color-darker;
    fill: $text-color-darker;

    &:hover,
    &:focus {
        background: $primary-color-lighter;
        color: $text-color-darker;
        fill: $text-color-darker;
    }

    &.is-loading {
        &:before,
        &:after {
            border-color: $text-color-darker;
        }
    }
}

.button--white {
    background: $primary-background-color;
    color: $primary-color;
    fill: $primary-color;

    &:hover,
    &:focus {
        background: darken($primary-background-color, 3%);
        color: $primary-color;
        fill: $primary-color;
    }

    &.is-loading {
        &:before,
        &:after {
            border-color: $primary-color;
        }
    }

    ::selection {
        background-color: rgba($primary-color, 0.25);
    }
}

.button--icon {
    padding: 1em 1.4em;

    svg {
        display: block;
        width: 1.5em;
        height: 1.5em;
        margin: 0 -0.4em;
    }
}

.button--small {
    padding: 0.75em 1.25em;
    font-size: 0.85rem;
}

.button--huge {
    border-radius: 1.5em;
    font-size: 1.25em;

    @include mq($from: sm) {
        border-radius: 2em;
        font-size: 1.5em;
    }
}

.button--link {
    display: block;
    margin: 0;
    padding: 0.5em 0;
    border: none;
    outline: none;
    background: none;
    font-size: 0.875em;
    color: $text-color;
    fill: $text-color;

    &:hover,
    &:focus {
        color: $primary-color;
        fill: $primary-color;
    }
}

.button__icon {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
    margin-top: -0.15em;
    margin-left: 0.25em;
    margin-right: -0.25em;

    svg {
        display: block;
        vertical-align: middle;
    }
}

.buttons {
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    margin-right: -0.5em;
    margin-bottom: -0.25em;

    > .button {
        margin-right: 0.5em;
        margin-bottom: 0.25em;
    }
}

.button__icon--pre {
    margin-left: -0.3em;
    margin-right: 0.3em;
}

.link--arrow {
    margin: -1rem 0 1rem 0;
    color: $primary-color;

    &:hover {
        color: rgba($primary-color, 0.8);
    }

    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
    }
}
