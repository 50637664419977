.layout {
    display: flex;
    flex-direction: column-reverse;
    margin: -1rem;

    @include mq($from: md) {
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.layout__item {
    flex-grow: 1;
    width: calc(100% - 1rem);
    padding: 1rem;

    img {
        display: block;
    }

    @include mq($from: md) {
        max-width: calc(50% - 1rem);
    }
}
