.article-detail-page__heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;

    @include mq($from: md) {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
}

.article-detail-page {
    margin-bottom: 2.5em;
    overflow: hidden;

    @include mq($from: sm) {
        margin-bottom: 4.5em;
    }

    strong {
        color: $text-color-darker;
    }
}

.article-detail-page__intro {
    position: relative;
    z-index: 1;
    margin-bottom: 1.25rem;

    @include mq($from: sm) {
        margin-bottom: 2rem;
    }
}

.article-detail-page__title {
    font-size: 1.75em;
    font-weight: 500;

    @include mq($from: sm) {
        font-size: 2em;
    }
}

.article-detail-page__image {
    display: block;
    min-width: calc(100% + #{$content-whitespace * 2});
    margin-right: -$content-whitespace;
    margin-left: -$content-whitespace;
}

.article-detail-page__background-and-higher {
    z-index: 2;
    position: relative;
    display: flex;
    justify-content: center;


    background: #fff;

    @include mq($from: md) {
        margin-top: -10rem;
        padding-top: 4rem;
    }
}

.article-detail-page__smaller {
    width: 700px;
}

.article-detail-page__content {
    > p:first-of-type {
        font-size: 1.1rem;
    }

    > p,
    > ul,
    > ol,
    > dl,
    > img,
    > figure,
    > iframe,
    .content-block {
        margin-bottom: 1.5em;

        @include mq($from: sm) {
            margin-bottom: 2.5em;
        }
    }

    > *:last-child {
        margin-bottom: 0;

        > .content-block {
            margin-bottom: 0;
        }
    }

    ol {
        padding-left: 18px;
    }

    > img,
    .content-block--video,
    .content-block--embed {
        display: block;
        width: 100%;

        @include mq($from: sm) {
            min-width: calc(100% + 6em);
            margin-left: -3em;
            margin-right: -3em;
        }
    }

    a:not(.button) {
        color: $primary-color;
    }

    figure {
        margin: 0;
    }

    @include mq($from: sm) {
        > p:first-of-type {
            font-size: 1.15rem;
        }
    }
}
