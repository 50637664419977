.stats {
    padding: 0;
    margin-bottom: 1rem;
}

.stats__heading {
    margin-bottom: 1.1rem;
    font-size: 1.1rem;
}

.stats__item {
    display: block;
    border-top: 1px solid $line-color;

    &:last-child {
        border-bottom: 1px solid $line-color;
    }
}

.stats__link {
    display: flex;
    align-items: center;
    padding: 1rem 0;
}

.stats__text {
    margin-right: 0.5rem;
    font-size: 0.9rem;
    font-weight: 400;
}

.stats__icon {
    display: block;
    width: 1rem;
    height: 1rem;
    margin-left: auto;
    fill: $primary-color;
}
