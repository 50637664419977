.article__other-articles-heading {
    margin-bottom: 1.75rem;
    font-size: 1.5rem;
}
.article-holder {
    display: flex;
    flex-wrap: wrap;
    margin: -$content-whitespace;
    padding: 0;
    margin-bottom: -#{$content-whitespace};
}

.article-holder--landing {
    margin-bottom: -$content-whitespace;
}

.article {
    display: block;
    flex: 0 0 100%;
    margin-bottom: 0;

    @include mq($from: sm) {
        flex-basis: 50%;
    }
}

.article--fullwidth {
    @include mq($from: sm) {
        flex-basis: 100%;
        margin-bottom: 2rem;
    }
}

.article--fullwidth-overview {
    @include mq($from: sm) {
        margin-bottom: 0.5rem;
    }
}

.article__content {
    height: 100%;
    padding: $content-whitespace;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-top: 0;
    }
}

.article__anchor {
    display: flex;
    height: 100%;

    p {
        display: none;
        color: $text-color;
        font-weight: 400;
    }

    &:hover,
    &:focus {
        cursor: pointer;

        p {
            color: $text-color;
        }
    }

    @include mq($from: sm) {
        p {
            display: inline-block;
        }
    }

    @include mq($from: sm) {
        flex-direction: column;
    }

    .article--fullwidth & {
        flex-direction: column;
        border-radius: $border-radius;

        @include mq($from: md) {
            flex-direction: row;
        }
    }
}

.article__image-holder {
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    width: 30%;
    margin-right: 1rem;
    border-radius: $border-radius;
    overflow: hidden;
    transition: transform 0.3s ease-in-out;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    img {
        object-fit: cover;
    }

    .article__anchor:hover &,
    .article__anchor:focus & {
        transform: scale(0.975);

        .article--fullwidth & {
            transform: none;
        }
    }

    @include mq($from: sm) {
        width: 100%;
        margin-bottom: 0.75rem;
    }


    .article--fullwidth & {
        width: 100%;
        min-height: 200px;
        margin-bottom: 1rem;

        img {
            min-height: 200px;
        }

        @include mq($from: sm) {
            min-height: 325px;

            img {
                min-height: 325px;
            }
        }

        @include mq($from: md) {
            width: 55%;
            min-height: 375px;
            margin-right: 3rem;
            margin-bottom: 0;

            img {
                min-height: 375px;
            }
        }
    }
}

.article__image {
    display: block;
    width: 100%;
    transition: transform 0.3s ease-in-out;

    .article__anchor:hover &,
    .article__anchor:focus & {
        transform: scale(1.075);
    }
}

.article__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    height: 100%;

    > * {
        max-width: 100%;
    }

    @include mq($from: sm) {
        justify-content: flex-start;

        .article--fullwidth & {
            justify-content: center;
        }
    }
}

.article__heading {
    margin-bottom: 0;
    font-size: 1rem;
    margin-top: -5px;

    .article__anchor:hover &,
    .article__anchor:focus & {
        color: $primary-color;
    }

    @include mq($from: sm) {
        font-size: 1.25rem;
        margin-top: 0;
    }

    .article--fullwidth & {
        margin-bottom: 0.75rem;
        margin-top: 0;
        font-size: 1.25rem;

        @include mq($from: sm) {
            font-size: 1.75rem;
        }
    }
}

.article__hr {
    width: calc(100% - #{$content-whitespace} * 2);
    margin: 0 auto;

    &:last-child {
        display: none;
    }

    @include mq($from: sm) {
        display: none;
    }
}
