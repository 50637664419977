.steps {
    margin: -$content-whitespace;
    padding: 0;
    counter-reset: steps;

    @include mq($from: md) {
        display: flex;
    }
}

.steps--vertical {
    display: block;
}

.steps--horizontal {
    display: block;
}

.step {
    position: relative;
    display: block;
    flex-basis: 0;
    flex-grow: 1;
    padding: $content-whitespace;
    counter-increment: steps;

    &:before {
        content: counter(steps);
        position: absolute;
        top: $content-whitespace;
        left: $content-whitespace;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        flex-grow: 0;
        width: 2.5rem;
        height: 2.5rem;
        margin-bottom: 1.25rem;
        border-radius: 50%;
        text-align: center;
        font-weight: 400;
        color: $text-color-darker;
        background-color: $primary-color-lighter;
    }

    &:last-child .seperator {
        display: none;
    }

    .seperator:last-child {
        margin-top: $content-whitespace * 2;
        margin-bottom: -$content-whitespace;

        @include mq($from: sm) {
            margin-top: $content-whitespace * 2.5;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.step--large {
    @include mq($from: sm) {
        padding: 3rem 20px;

        &:before {
            top: 3rem;
        }
    }
}

.step--bordered {
    position: relative;

    .seperator {
        position: absolute;
        top: 0;
        left: 20px;
        width: calc(100% - 40px);
    }

    &:last-child .seperator {
        display: block;
    }
}

.step--icon {
    &:before {
        content: '';
    }
}

.step__content {
    padding-top: 3.5em;

    .steps.steps--vertical & {
        padding-top: 3.5em;
        padding-left: 0;

        @include mq($from: sm) {
            padding-top: 0;
            padding-left: 3.5em;
        }
    }

    .steps.steps--horizontal & {
        @include mq($from: sm) {
            display: flex;
            padding-top: 0;
            padding-left: percentage(1/12);
        }
    }

    img {
        display: block;
        width: 100%;
        margin-bottom: 0.5em;
    }

    h3:first-child {
        margin-bottom: 0.4em;
    }

    a {
        color: $primary-color;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    @include mq($from: sm) {
        h3:first-child {
            margin-top: 0.4em;
        }
    }
}

.step__text {
    margin-bottom: 1.25em;

    @include mq($from: sm) {
        margin-bottom: 0;

        .steps.steps.steps--horizontal & {
            flex: none;
            width: percentage(7/12);

            h3 {
                margin-top: 0;
            }

            > * {
                max-width: 80%;
            }
        }
    }
}

.step__icon {
    position: absolute;
    top: $content-whitespace;
    left: $content-whitespace;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0.5em;
}
