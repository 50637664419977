// Modal on prevention tip detail
#prevention-modal { // stylelint-disable-line selector-max-specificity
    .modal__content h3:first-child { // stylelint-disable-line selector-max-specificity
        margin-top: 0;
    }
}

.prevention-tip-detail {
    margin-bottom: 1.5em;
    overflow: hidden;

    @include mq($from: sm) {
        margin-bottom: 2.5em;
    }

    strong {
        color: $text-color-darker;
    }
}

.prevention-tip-detail__intro {
    position: relative;
    z-index: 1;
    padding-top: 0;
    margin-bottom: 1.25rem;

    p {
        font-size: 1.2rem;
    }

    > .container {
        margin-bottom: 2rem;

        @include mq($from: sm) {
            margin-bottom: 3rem;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    @include mq($from: sm) {
        margin-bottom: 2rem;
    }

    @include mq($from: md) {
        padding-top: 5rem;
    }
}

.prevention-tip-detail__title {
    margin-bottom: 1rem;
    font-size: 1.5em;

    @include mq($from: sm) {
        font-size: 2em;
    }
}

.prevention-tip-detail__image {
    display: block;
    margin-bottom: 1.25em;

    @include mq($from: sm) {
        margin-bottom: 3em;
    }
}

.prevention-tip-detail__image--outset {
    display: none;
    min-width: calc(100% + #{$content-whitespace * 2});
    margin-left: -$content-whitespace;
    margin-right: -$content-whitespace;

    @include mq($from: sm) {
        min-width: calc(100% + 12em);
        margin-left: -6em;
        margin-right: -6em;
    }

    @include mq($from: md) {
        display: block;
    }
}

.prevention-tip-detail__image--mobile {
    display: block;

    @include mq($from: md) {
        display: none;
    }
}

.prevention-tip-detail__content {
    ul,
    ol,
    dl,
    img,
    figure,
    iframe,
    .content-block {
        margin-bottom: 1.5em;

        @include mq($from: sm) {
            margin-bottom: 2.5em;
        }
    }

    p,
    .content-block {
        margin-bottom: 1em;

        @include mq($from: sm) {
            margin-bottom: 1.5em;
        }
    }

    > *:last-child {
        margin-bottom: 0;

        > .content-block {
            margin-bottom: 0;
        }
    }

    ol {
        padding-left: 18px;
    }

    img,
    .content-block--video {
        display: block;

        .prevention.prevention--news & {
            @include mq($from: sm) {
                min-width: calc(100% + 6em);
                margin-left: -3em;
                margin-right: -3em;
            }
        }
    }

    a:not(.button) {
        color: $primary-color;
    }

    figure {
        margin: 0;
    }
}
