.product-discount {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    width: 100px;
    height: 100px;
    padding: 1rem;
    border-radius: 100px;
    background-color: #e9a838;
    background-image: linear-gradient(0deg, #e78231, #ecd340);
    color: #fff;
    text-align: center;
}

.product-discount__number {
    display: block;
    margin-top: -10px;
    margin-bottom: -10px;
    font-size: 1.5rem;
}

.product-discount__text {
    font-size: 0.8rem;
}
