.marker-tooltip__heading {
    display: flex;
    padding: 0.75rem 1.5rem 0.75rem 0.75rem;
    align-items: center;
    margin-bottom: 0.25rem;
    border-bottom: 1px solid $line-color;

    h4 {
        margin-bottom: 0;
        margin-right: 0.5rem;
        font-size: 0.9rem;
        font-weight: 500;
    }
}

.marker-tooltip__body {
    padding: 0.75rem;
    margin-bottom: 0.25rem;
    border-bottom: 1px solid $line-color;

    dl {
        display: flex;
        flex-wrap: wrap;
        width: 104%;
        margin: 0 -1rem -0.25rem 0;
    }

    dt,
    dd {
        font-size: 0.8rem;
        margin: 0 1rem 0.25rem 0;
    }

    dt {
        width: calc(#{percentage(4/5)} - 1rem);
        color: $text-color;
    }

    dd {
        width: calc(#{percentage(1/5)} - 1rem);
        text-align: right;
        font-weight: 500;
    }
}

.marker-tooltip__body-heading {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    font-size: 0.8rem;
}

.marker-tooltip__link-holder {
    padding: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.marker-tooltip__link {
    color: $primary-color !important;
    font-size: 0.8rem;
}

