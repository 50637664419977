.table {
    width: 100%;
    border-collapse: collapse;

    td,
    th {
        vertical-align: top;
        padding: 0.75em;
        border-bottom: 1px solid $line-color;
        text-align: left;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    th {
        color: $text-color-lighter;
        font-weight: normal;
        line-height: 1.5;
    }

    thead tr:last-child th {
        vertical-align: bottom;
    }
}
