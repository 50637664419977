.how-it-works-link {
    display: flex;
    align-items: center;
    padding: 0;
    margin-bottom: 1.5rem;
    border: none;
    outline: none;
    background: none;
    color: $primary-color;
    font-size: 0.8rem;
    cursor: pointer;
}

.how-it-works-link--flat {
    margin-bottom: 0;
}

.how-it-works-link__icon {
    width: 1rem;
    height: 1rem;
    margin-right: 0.5rem;
    fill: $primary-color;
}
