.odometer-b {
    display: flex;
    padding: 40px 120px;
    border-radius: $border-radius;
    background-color: $secondary-background-color;

    @include mq($until: lg) {
        padding: 40px 60px;
    }

    @include mq($until: md) {
        flex-direction: column;
        padding: 20px;
    }

    .odometer__label:first-of-type {
        text {
            transform: translate(154px, 360px);
            font-size: 24px;
        }

        text:last-of-type {
            display: none;
        }
    }

    .odometer__label:last-of-type {
        g circle {
            r: 120px;
        }

        circle {
            r: 124px;
        }

        text {
            font-size: 40px;
        }
    }
}

.odometer-b__meta {
    flex-grow: 1;

    @include mq($until: md) {  
        margin-top: 40px;
    }

    h1 {
        font-size: 28px;
        font-weight: 400;
    }

    .how-it-works-link {
        font-size: 16px;
        font-weight: 400;
    }
}
