$steps-cta-breakpoint: md !default;

.steps-cta {
    display: block;
    margin-bottom: 1rem;
}

.steps-cta__title {
    margin-bottom: 1em;
}

.steps-cta__content {
    display: flex;

    @include mq($until: $steps-cta-breakpoint) {
        flex-direction: column;
    }

    > * {
        @include mq($from: $steps-cta-breakpoint) {
            flex-grow: 1;
            flex-basis: 0;
            margin: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.steps-cta__steps {
    display: flex;
    margin: 1rem 0;
    padding: 0;

    @include mq($from: $steps-cta-breakpoint) {
        margin: 0;
        padding-right: 3rem;
    }
}

.steps-cta__step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    flex-basis: 0;
    margin: 0;
    padding: 0;
}

.steps-cta__step__button {
    display: block;
    margin: 0;
    padding: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    background: none;

    &:hover,
    &:focus {
        color: $primary-color;
    }
}

.steps-cta__step__image {
    display: inline-block;
    width: 140px;
    height: 140px;
    margin-bottom: 1rem;
    border-radius: 50%;
    overflow: hidden;
    background-color: $secondary-background-color;

    @include mq($until: $steps-cta-breakpoint) {
        width: 100px;
        height: 100px;
    }
}

.steps-cta__step__title {
    font-size: 0.95rem;
    line-height: 1.5 !important;
}

.steps-cta__step__title__icon {
    position: relative;
    top: 0.1em;
    display: inline-block;
    width: 1em;
    height: 1em;
    margin: 0 0.3em;
    fill: $primary-color;
}
