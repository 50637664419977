html {
    font-family: $base-font-family;
    font-size: $base-font-size;
    line-height: $base-line-height;
    color: $text-color;
    fill: $text-color;

    @include mq($from: md) {
        font-size: $base-font-size-responsive;
    }
}

h1,
h2,
h3,
h4 {
    margin: 0 0 0.4em;
    line-height: 1.4;
    font-weight: normal;
    color: $heading-text-color;
}

h1 {
    font-size: 1.5rem;
}

h2 {
    font-size: 1.25rem;
}

p {
    margin: 0 0 1.25em;

    small {
        display: inline-block;
        font-size: 0.875rem;
        color: $text-color-lighter;
    }
}

a {
    color: inherit;
    fill: currentColor;
    font-weight: 500;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;

    &:hover,
    &:focus {
        color: $primary-color;
        fill: $primary-color;
    }
}

.text-link {
    color: $primary-color;
}

ul,
ol,
dl {
    margin: 0 0 1em;
}

.text-gradient {
    display: inline-block;
}

.text-gradient--primary {
    color: $primary-color;
    background: -webkit-linear-gradient(-45deg, $secondary-color, $primary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.text-gradient--secondary {
    color: $quaternary-color;
    background: -webkit-linear-gradient(-45deg, $tertiary-color, $quaternary-color);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

::selection {
    background-color: rgba($primary-color, 0.25);
}
