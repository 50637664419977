.app,
.app > .container,
.routes-container,
.routes-container > .container {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex: 1 0 auto;
}

.app {
    width: 100%;
    max-width: map-get($breakpoints, $content-breakpoint);
    margin: 0 auto;
    overflow-x: hidden;
    overflow-y: auto;
}

.app--responsive {
    max-width: 100%;
}

.routes-container {
    padding-bottom: $content-whitespace;
}

.app > .container,
.routes-container > .container {
    align-items: flex-start;
    height: 100%;

    @include mq($from: $content-breakpoint) {
        justify-content: center;
    }
}

.content--container {
    margin-bottom: 3rem;
}
