.municipality-dl {
    display: flex;
    flex-wrap: wrap;
    margin-right: -1rem;
    font-size: 0.8rem;
}

.municipality-dl__item {
    width: calc(50% - 1rem);
    margin-right: 1rem;
    margin-left: 0;
    margin-bottom: 0.5rem;
}

.municipality-dl__item--term {
    color: $text-color-lighter;
}

.municipality-dl__item--description {
    font-weight: 600;
}
