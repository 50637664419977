.tooltip-trigger {
    position: relative;
}

.tooltip {
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 0.2rem);
    left: 50%;
    transform: translateX(-50%);
    display: block;
    width: 125px;
    min-width: 100px;
    max-width: 200px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    transition: opacity 0.2s;
    background-color: #333;
    color: #fff;
    font-size: 0.65rem;
    text-align: left;
    opacity: 0;
    pointer-events: none;

    .tooltip-trigger:hover &,
    .tooltip-trigger:focus & {
        opacity: 1;
        pointer-events: all;
    }

    &:after {
        content: '';
        position: absolute;
        top: calc(100% - 1px);
        left: 50%;
        border: 0.5rem solid transparent;
        border-top-color: #333;
        transform: translateX(-50%);
        pointer-events: none;
    }
}

.tooltip__test {
    display: block;
    margin-bottom: 0;
}
