.quote {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 2rem;
    margin: 0;
    background-color: $secondary-background-color;
    border-radius: $border-radius;

    @include mq($from: md) {
        min-width: calc(100% + 6em);
        margin-left: -3em;
        margin-right: -3em;
    }

    @include mq($until: md) {
        flex-wrap: wrap;
    }
}

.quote__icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.75rem;
    fill: $primary-color;

    @include mq($until: md) {
        width: calc(10% - 0.75rem);
        order: 1;
    }
}

.quote__content {
    @include mq($until: md) {
        width: 90%;
        order: 2;
    }
}

.quote__text {
    display: block;
    font-size: 1.17rem;
    margin-bottom: 0.4rem;
    color: $text-color-darker;
}

.quote__author-dash {
    margin-right: 0.5rem;
}

.quote__author {
    display: block;
    font-style: italic;
}

.quote__source {
    display: block;
    margin-top: 1rem;
    font-size: 0.8rem;
}

.quote__image {
    flex-shrink: 0;
    margin-left: 1rem;

    img {
        display: block;
        border-radius: 50%;
        margin: 0 auto;
    }
    @include mq($from: md) {
        margin-left: 1rem;
    }
    @include mq($until: md) {
        order: 0;
        width: 100%;
        margin-bottom: 1rem;
    }
}
