.nav-main {
    display: none;
    width: 100%;
    padding-top: 2rem;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;

    li {
        display: block;

        &.current a {
            color: $primary-color;
        }
    }

    li:last-child {
        margin-right: 0;
    }

    a {
        display: flex;
        align-items: center;
        padding: 0.6rem 0;
        font-size: 1.3rem;
        font-weight: 500;
        color: $text-color;

        svg {
            width: 1rem;
            height: 1rem;
            margin-left: 0.5rem;
            fill: $primary-color;
        }
    }

    .nav-main-state:checked ~ .header & {
        display: block;
    }

    @include mq($from: md) {
        display: block;
        width: auto;
        padding-top: 0;

        li {
            display: inline-block;
            margin-right: 2rem;
        }

        a {
            font-size: 0.8rem;

            svg {
                display: none;
            }
        }
    }
}

.nav-main-state {
    display: none;
}

.nav-main-toggle {
    display: block;
    width: 1.5rem;
    height: 1.2rem;
}

.nav-main-toggle__icon {
    position: relative;
    top: 50%;

    &,
    &:before,
    &:after {
        display: block;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        background-color: $primary-color;
        transition: all 0.15s;
        transition-property: top, width, transform;
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
    }

    &:before {
        transform: translateY(-0.5rem);
    }

    &:after {
        transform: translateY(0.5rem);
    }

    @include mq($from: md) {
        display: none;
    }

    .nav-main-state:checked ~ .header & {
        background-color: transparent;

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }
}
