$primary-color: #00cbc6 !default;
$primary-color-light: #def4f4 !default;
$primary-color-lighter: #d7f4f4 !default;
$secondary-color: #00cc81 !default;
$tertiary-color: #ff7835 !default;
$quaternary-color: #f00 !default;
$primary-background-color: #fff !default;
$secondary-background-color: #f8f9ff !default;
$line-color: #eee !default;
$text-color: #666 !default;
$text-color-lighter: mix(#fff, $text-color, 40%) !default;
$text-color-darker: #000 !default;
$heading-text-color: $text-color-darker !default;
$primary-gradient: linear-gradient(-45deg, $secondary-color, $primary-color) !default;
$primary-gradient-lighter: linear-gradient(-45deg, mix(#fff, $secondary-color, 15%), mix(#fff, $primary-color, 15%)) !default;
$secondary-gradient: linear-gradient(-45deg, $tertiary-color, $quaternary-color) !default;
