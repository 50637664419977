.list {
    display: block;
    margin: 0 0 1em;
    padding: 0;

    .section.section--center .section__title + & {
        margin-top: 1.5em;
    }
}

.list--horizontal {
    text-align: left;

    @include mq($from: md) {
        display: flex;
    }
}

.list--icons {
    list-style: none;
}

.list--circled-icons {
    margin-bottom: 1.5em;
    list-style: none;
}

.list__item {
    display: block;
    margin-bottom: 0.2em;

    .list.list--icons & {
        position: relative;
        padding-left: 1.5em;

        svg {
            position: absolute;
            top: 0.3em;
            left: 0;
            width: 1em;
            height: 1em;
        }
    }

    .list.list--horizontal & {
        flex-basis: 0;
        flex-grow: 1;
        padding: 0 $content-whitespace;

        i {
            left: $content-whitespace;
        }
    }

    .list.list--circled-icons & {
        position: relative;
        padding-left: 3.5em;
        margin-bottom: 1.4em;
        margin-top: 1.4em;

        &:last-child {
            margin-bottom: 0;
        }

        i {
            position: absolute;
            top: -0.5em;
            left: 0;
            padding: 0.7em;
            border-radius: 50%;
            background-color: $primary-color-lighter;

            svg {
                display: block;
                width: 1.3em;
                height: 1.3em;
                padding: 1px;
                fill: $text-color-darker;
            }
        }
    }

    .list.list--horizontal.list--circled-icons & {
        margin-top: 1em;
        margin-bottom: 1em;

        @include mq($from: md) {
            margin-top: 0;
            margin-bottom: 0;
        }
    }
}

.list__item--primary {
    .list.list--circled-icons & {
        i {
            top: 0;
            background: $primary-gradient;

            svg {
                fill: #fff;
            }
        }
    }
}

.list__item__title {
    display: block;
    color: $text-color-darker;
    line-height: 1.6;
}

.list__item__subtitle {
    display: block;
    color: $text-color-lighter;
    font-size: 0.75em;
    line-height: 1.6;
}
