.form-choice {
    position: relative;
    display: block;
    margin-bottom: 0.75rem;

    &:last-child {
        margin-bottom: 0;
    }

    .form--inline & {
        margin-bottom: 0;
    }

    .form-choice-list--inline & {
        display: inline-block;
        margin-bottom: 0;
        margin-right: 0.5rem;
    }
}

.form-choice__input {
    position: absolute;
    z-index: -1;
    margin: 0.25rem 0.5rem 0 -1.25rem;
    opacity: 0;

    &:checked ~ .form-choice__label:before {
        border-color: $line-color;
        background-color: $secondary-background-color;
        color: $text-color;
        background-image: url('../../../images/sprites/checkmark.png');
    }

    &:focus ~ .form-choice__label {
        &:before {
            border: 1px solid $line-color;
            outline: 0;
        }
    }
}

.form-choice__label {
    position: relative;
    display: inline-flex;
    min-height: 1.5rem;
    margin-bottom: 0;
    cursor: pointer;
    font-size: 0.8rem;

    &:before {
        content: '';
        display: block;
        box-sizing: content-box;
        flex: 1 0 1rem;
        width: 1rem;
        height: 1rem;
        margin-top: 5px;
        margin-right: 0.5rem;
        border: 1px solid $line-color;
        border-radius: $border-radius;
        background-color: $secondary-background-color;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 70% 70%;
        pointer-events: none;
        user-select: none;
    }

    .form-choice.is-disabled & {
        color: #f1f1f1;
        cursor: not-allowed;
    }

    .has-error & {
        color: #f00;
    }
}
