@import '../generic/animations';

.loader {
    position: relative;
    display: inline-block;
    width: 2em;
    height: 2em;

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 2px solid $primary-color;
        border-radius: 50%;
    }

    &:before {
        border-color: $text-color;
        opacity: 0.075;
    }

    &:after {
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        animation: spin 1s linear infinite;
    }
}

.loader--small {
    width: 1.25em;
    height: 1.25em;
}

.loader--large {
    width: 3em;
    height: 3em;
}

.loader--center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
