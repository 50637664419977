$municipality-risk-states: (
    1: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%))
    ),
    2: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%))
    ),
    3: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%))
    ),
    4: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%))
    ),
    5: (
        color: linear-gradient(-45deg, mix(#fff, #f7661c, 10%), mix(#fff, #ffb62f, 10%))
    ),
    6: (
        color: linear-gradient(-45deg, mix(#fff, #f7661c, 10%), mix(#fff, #ffb62f, 10%))
    ),
    7: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%))
    ),
    8: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%))
    ),
    9: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%))
    ),
    10: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%))
    )
) !default;

.municipality-table-holder {
    overflow-x: auto;
    border: 1px solid $line-color;
    border-radius: 0 0 $border-radius $border-radius;
}

.municipality-table-holder--outbreak {
    border-width: 1px 0 1px 0;
    border-radius: 0;

    @include mq($from: lg) {
        border-width: 1px;
        border-radius: 0 0 $border-radius $border-radius;
    }
}

.municipality-table {
    margin: 0;
    margin-right: 20px;
    border-spacing: 0;
    min-width: 100%;

    td,
    th {
        padding: 0.5rem;
        border-bottom: 1px solid $line-color;
        text-align: left;
        font-size: 0.8rem;
        width: 25%;
    }

    tr:last-child td {
        border-bottom: 0;
    }

    th {
        color: $text-color-lighter;
    }

    .municipality-table-holder--outbreak & {
        td,
        th {
            &:first-child {
                padding-left: 20px;
            }

            &:last-child {
                padding-right: 20px;
            }
        }
    }

    @include mq($from: sm) {
        width: 100%;
        border-radius: 0 0 $border-radius $border-radius;
    }

    @include mq($from: lg) {
        .municipality-table-holder--outbreak & {
            td,
            th {
                &:first-child {
                    padding-left: 0.5rem;
                }

                &:last-child {
                    padding-right: 0.5rem;
                }
            }
        }
    }
}

.municipality-table__button--sort {
    position: relative;
    padding-right: 15px;
    padding-left: 0;
    outline: 0;
    border: 0;
    text-align: left;
    background: 0;
    cursor: pointer;
    color: $text-color;
    font-weight: 500;

    &:after,
    &:before {
        content: '';
        position: absolute;
        display: block;
        height: 2px;
        width: 8px;
        background-color: $text-color-lighter;
        right: 0;
        top: 50%;
        transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
    }

    &:before {
        transform-origin: left;
        transform: translateX(-2px);
    }

    &:after {
        transform-origin: right;
        transform: translateX(2px);
    }

    &.active {
        color: $text-color-darker;

        &:after,
        &:before {
            background-color: $primary-color;
        }

        &.up {
            &:before {
                transform: rotate(45deg) translateX(-2px);
            }

            &:after {
                transform: rotate(-45deg) translateX(2px);
            }
        }

        &.down {
            &:before {
                transform: rotate(-45deg) translateX(-2px);
            }

            &:after {
                transform: rotate(45deg) translateX(2px);
            }
        }
    }
}

.municipality-table__link {
    display: inline-block;
    vertical-align: top;
    margin-right: 0.75rem;
    color: $primary-color;
}

.municipality-table__risk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-image: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%));
    color: #fff;
}

.municipality-table__district-name {
    display: block;
    vertical-align: top;
    min-width: 5rem;
    word-break: break-word;

    @include mq($from: sm) {
        display: inline-block;
    }
}

td.municipality-table__show-all {
    text-align: center;
    color: $primary-color;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}

.municipality-table__icon {
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 1rem;

    svg {
        width: 1.2rem;
        height: 1.2rem;
        fill: $primary-color;
    }
}

@each $value, $state in $municipality-risk-states {
    .municipality-table__risk[data-prediction='#{$value}'] {
        background-image: map-get($state, 'color');
    }
}
