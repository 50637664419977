$modal-side-padding: 1rem;
$modal-side-padding--md: 2.25rem;

.modal {
    position: fixed;
    z-index: $z-index-modal;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.modal__backdrop {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    border: none;
    background: rgba($text-color, 0.4);
}

.modal__content-wrapper {
    display: block;
    height: 100%;
    margin: auto;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    outline: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.modal__content {
    position: relative;
    width: 100%;
    max-width: 650px;
    padding: 1.25rem $modal-side-padding;
    margin: 2.5rem auto;
    border-radius: $border-radius * 2;
    background: $primary-background-color;

    @include mq($from: md) {
        padding: 2.5rem $modal-side-padding--md;
        margin: 7.5rem auto;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    h3 {
        display: block;
        margin: 1.5em 0 0.5em;
        font-weight: bold;
        font-size: 1rem;
    }

    a {
        color: $primary-color;
    }
}

.modal__content__close {
    position: absolute;
    right: -1em;
    top: -1em;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.4em;
    height: 2.4em;
    padding: 0.75em;
    border: none;
    border-radius: 50%;
    outline: none;
    background-color: $primary-color;
    background-image: $primary-gradient;
    fill: #fff;
    cursor: pointer;

    i,
    svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

.modal__splitter {
    width: calc(100% + #{$modal-side-padding} * 2);
    margin-top: 1.5rem;
    margin-left: -$modal-side-padding;
    margin-bottom: 1.5rem;

    @include mq($from: md) {
        width: calc(100% + #{$modal-side-padding--md} * 2);
        margin-left: -$modal-side-padding--md;
    }
}
