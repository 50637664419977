.prevention-tip-card-holder {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-right: -2rem;
    margin-bottom: -2rem;
}

.prevention-tip-card {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-grow: 0;
    width: calc(100% - 2rem);
    margin-right: 2rem;
    margin-bottom: 2rem;

    @include mq($from: md) {
        width: calc(50% - 2rem);
    }

    @include mq($from: lg) {
        width: calc(#{percentage(1/3)} - 2rem);
    }
}

.prevention-tip-card-holder--locktool {
    .prevention-tip-card {
        @include mq($from: md) {
            width: calc(50% - 2rem);
        }

        @include mq($from: lg) {
            width: calc(50% - 2rem);
        }
    }
}

.prevention-tip-card--wide {
    width: 100%;
    margin-right: 0;

    @include mq($from: md) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 2.5rem;
        flex-direction: row;
    }
}

.prevention-tip-card__header {
    overflow: hidden;
    border-radius: $border-radius $border-radius 0 0;
    flex-shrink: 0;
    display: flex;

    img {
        width: 100%;
        max-height: 280px;
        object-fit: cover;
    }

    @include mq($from: md) {
        img {
            max-height: 280px;
        }

        .prevention-tip-card--wide & {
            width: 50%;
            border-radius: $border-radius 0 0 $border-radius;

            img {
                min-height: 100%;
                margin-bottom: 0;
            }
        }
    }

    @include mq($from: lg) {
        img {
            max-height: 220px;
        }

        .prevention-tip-card--wide & {
            img {
                min-height: 100%;
                max-height: 280px;
                margin-bottom: 0;
            }
        }
    }
}

.prevention-tip-card__image {
    padding-bottom: 60%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include mq($from: md) {
        .prevention-tip-card--wide & {
            height: 100%;
            padding-bottom: 0;
        }
    }
}

.prevention-tip-card__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    border: 1px solid $line-color;
    border-top-width: 0;
    border-radius: 0 0 $border-radius $border-radius;

    @include mq($from: md) {
        .prevention-tip-card--wide & {
            width: 50%;
            border-top-width: 1px;
            border-left-width: 0;
            border-radius: 0 $border-radius $border-radius 0;
        }
    }
}

.prevention-tip-card__content {
    padding: 1rem 1.5rem 1rem;
}

.prevention-tip-card__stats-bar {
    display: flex;
    margin-bottom: 1rem;
}

.prevention-tip-card__icon-holder {
    display: flex;
    align-items: center;
    margin-right: 1rem;

    &:first-child {
        margin-right: 0;
    }
}

.prevention-tip-card__icon {
    display: flex;
    width: 0.9rem;
    height: 0.9rem;
    margin-right: 0.15rem;
    fill: #dbf5f5;

    svg {
        width: 0.9rem;
        height: 0.9rem;
        fill: #dbf5f5;
    }

    &:last-child {
        margin-right: 0;
    }
}

.prevention-tip-card__icon-splitter {
    margin: 0 0.45rem;
    font-size: 0.9rem;
    color: $line-color;
}

.prevention-tip-card__icon--filled {
    fill: $primary-color;
    svg {
        fill: $primary-color;
    }
}

.prevention-tip-card__price {
    margin-left: auto;
    font-size: 0.9rem;
    color: $primary-color;
}

.prevention-tip-card__heading {
    width: 100%;
    font-size: 1rem;
    font-weight: 500;
}

.prevention-tip-card__explanation {
    width: 100%;
    margin-bottom: 0;
    font-size: 0.9rem;
    color: $text-color;
    font-weight: 400;
}

.prevention-tip-card__footer {
    margin-top: auto;
    padding: 0 1.5rem 1rem;
}

.prevention-tip-card__button {
    .prevention-tip-card:hover &,
    .prevention-tip-card:focus & {
        fill: #fff;
        color: #fff;
        background-color: $primary-color;
        background-image: $primary-gradient;
    }
}
