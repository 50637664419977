@import '../generic/mixins';

h1,
h2,
h3,
h4 {
    @include responsive-mq($from: md) {
        line-height: 1.25;
    }
}

h1 {
    @include responsive-mq($from: md) {
        font-size: 2rem;
    }
}

h2 {
    @include responsive-mq($from: md) {
        font-size: 1.5rem;
    }
}

figure {
    margin: 0;
}

figcaption {
    font-size: 0.8em;
}

.link-color {
    color: $primary-color;
}
