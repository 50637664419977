.header {
    .app & {
        margin-top: -$content-whitespace;
    }

    a {
        font-size: 0.85rem;
        font-weight: normal;
        color: $text-color-darker;

        @include mq($from: sm) {
            font-size: 1rem;
        }

        &:hover,
        &:focus {
            color: $primary-color;
        }
    }

    .nav-main-state:checked ~ & {
        position: fixed;
        z-index: 300;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100vh;
        background-color: #fff;
    }

    &.header--inbraakbarometer {
        .nav-main-state:checked ~ & {
            top: 20px;
        }
    }

    &.header--inbraakbarometer-twig {
        .nav-main-state:checked ~ & {
            top: 0;
        }
    }
}

.header__content {
    display: flex;
    align-items: center;
    padding: 1rem 0;

    .header.header--with-nav & {
        flex-wrap: wrap;
    }

    @include mq($from: md) {
        .header.header--with-nav & {
            flex-wrap: nowrap;
            flex-direction: row;
        }
    }
}

.header__logo {
    position: relative;
    display: block;
    box-sizing: content-box;
    flex-shrink: 1;
    width: 162px;
    height: 41px;
    margin-right: auto;
    padding-right: 20px;
    color: $primary-color;
    fill: $primary-color;

    @include mq($from: sm) {
        width: 162px;
        height: 41px;
        margin-bottom: 0;
    }

    &:only-child {
        margin-left: auto;
        margin-right: auto;
        padding: 0;
    }

    svg,
    object {
        display: block;
        width: 100%;
        height: 100%;
    }

    object {
        position: relative;
        z-index: -1;
    }

    .header--inbraakbarometer & {
        width: 230px;
        height: 37px;
    }
}

.header__note {
    margin-right: 1.5em;
    color: $text-color-darker;

    @include mq($until: sm) {
        display: none;
    }
}

.header__backlink {
    flex-shrink: 0;
}

.header__backlink__icon {
    position: relative;
    top: -0.1em;
    width: 1.4em;
    height: 1.4em;
    margin-left: 0.5em;
    fill: $primary-color;
}

.header__cta {
    display: inline-block;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    color: $text-color-darker;
    cursor: pointer;

    &:hover,
    &:focus {
        color: $primary-color;
    }
}

.header__cta__label {
    &:not(:only-child) {
        display: none;

        @include mq($from: md) {
            display: inline;
        }
    }
}

.header__cta__icon {
    position: relative;
    top: -0.1em;
    display: inline-block;
    vertical-align: middle;
    width: 2em;
    height: 2em;
    margin-left: 0.5em;
    padding: 0.55em;
    border-radius: 50%;
    background-color: $primary-color;
    background-image: $primary-gradient;
    fill: #fff;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        margin-left: -0.05em;
        padding: 1px;
    }
}
