.municipality-page-intro {
    margin-bottom: 1.25rem;
}

.municipality-page-intro--half {
    @include mq($from: md) {
        width: 50%;
    }
}

.municipality-page-intro__heading {
    margin-bottom: 0;
}

.municipality-page-intro__subheading + p {
    margin-top: 0.5rem;
}
