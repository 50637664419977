.municipality-blocks {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 1rem);
    margin-right: -1rem;
    margin-bottom: -1rem;

    @include mq($from: md) {
        width: calc(100% + 2rem);
        margin-right: -2rem;
        margin-bottom: -2rem;
    }

    @include mq($from: lg) {
        flex-wrap: nowrap;
    }
}

.municipality-blocks--map-table {
    align-items: flex-start;
}

.municipality-blocks__item {
    flex-shrink: 0;
    width: calc(100% - 1rem);
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid $line-color;
    border-radius: $border-radius;

    @include mq($from: md) {
        width: calc(100% - 2rem);
        margin-right: 2rem;
        margin-bottom: 2rem;
        padding: 2rem;
    }
}

.municipality-blocks__item--gray {
    border: 1px solid $secondary-background-color;
    background-color: $secondary-background-color;
}

.municipality-blocks__item--outbreak {
    width: calc(100% + 25px);
    margin-left: -20px;
    border-radius: 0;
    padding: 20px;

    @include mq($from: md) {
        width: calc(100% + 5px);
    }

    @include mq($from: lg) {
        width: calc(100% - 2rem);
        margin-left: 0;
        border-radius: $border-radius;
    }
}

.municipality-blocks__item--map {
    padding: 0;
    border-width: 0;
    
    @include mq($from: lg) {
        width: calc(50% - 2rem);
    }
}

.municipality-blocks__table-header {
    border-width: 1px 0 0;
    border-style: solid;
    border-color: $line-color;
    padding: 1rem 20px;

    @include mq($from: lg) {
        padding: 1rem;
        border-width: 1px 1px 0;
        border-radius: $border-radius $border-radius 0 0;
    }
}

.municipality-blocks__item--table {
    padding: 0;
    border: none;

    @include mq($from: lg) {
        width: calc(50% - 2rem);
    }
}

.municipality-blocks__item--general {
    width: 100%;

    @include mq($from: lg) {
        width: calc(#{percentage(1/3)} - 2rem);
    }
}

.municipality-blocks__item--full {
    @include mq($from: lg) {
        width: calc(100% - 2rem);
    }
}

.municipality-blocks__barchart-header {
    display: flex;
    flex-direction: column;

    @include mq($from: lg) {
        flex-direction: row;
    }
}

.municipality-blocks__data {
    @include mq($from: lg) {
        margin-left: auto;
    }
}

.municipality-blocks__data-item {
    display: inline-block;
    margin-right: 1rem;
    font-size: 0.9rem;
    color: $text-color-lighter;

    strong {
        color: $text-color;
    }

    @include mq($from: lg) {
        &:first-child {
            margin-left: 1rem;
        }
    }
}
