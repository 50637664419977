$low: #00cbc6;
$medium: #ffb62f;
$high: #ff33a7;

$predictionLevelColors : (
    1:$low,
    2:$low,
    3:$low,
    4:$low,
    5:$medium,
    6:$medium,
    7:$high,
    8:$high,
    9:$high,
    10:$high,
) !default;

.statistics__title {
    margin-bottom: 0.65em;
}

.statistics__blocks {
    margin-bottom: 1rem;
}

.statistics__table {
    a {
        font-weight: normal;
        color: $primary-color;
    }

    @include mq($until: md) {
        th,
        td {
            &:last-child {
                display: none;
            }
        }
    }
}

.statistics-blocklist {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    padding-bottom: 1.5rem;
    margin-right: -1rem;
    margin-bottom: -1rem;
    border-radius: 3px;
    list-style: none;
    background-color: #fff;

    @each $i, $color in $predictionLevelColors {
        &[data-prediction='#{$i}'] {
            .statistics-blocklist__highlighted-text {
                color: $color;
            }
        }
    }

    @include mq($from: sm) {
        flex-wrap: nowrap;
        width: calc(100% + 1rem);
    }
}

.statistics-blocklist__highlighted-text {
    min-width: 1.3rem;
    font-size: 1.8rem;
}

.statistics-blocklist__item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 1rem;
    margin-bottom: 1rem;
    padding: 0.5rem;
    border: 1px solid $line-color;
    border-radius: $border-radius;
    font-size: 0.8rem;
    text-align: center;

    @include mq($from: sm) {
        width: percentage(1/3);
    }
}

.statistics-blocklist__help-icon {
    position: absolute;
    right: 0;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    fill: $primary-color;
}

.statistics-blocklist__help {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    display: block;
    width: 200px;
    height: 1.5rem;
    margin-left: auto;
    transform: translateY(0);
}

.statistics-blocklist__tooltip {
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 0.2rem);
    right: -0.65rem;
    display: block;
    max-width: 200px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    transition: opacity 0.2s;
    background-color: #333;
    color: #fff;
    font-size: 0.65rem;
    text-align: left;
    opacity: 0;
    pointer-events: none;

    .statistics-blocklist__help-icon:hover + &,
    .statistics-blocklist__help-icon:focus + & {
        opacity: 1;
        pointer-events: all;
    }

    &:after {
        content: '';
        position: absolute;
        top: calc(100% - 1px);
        right: 5px;
        border: 0.5rem solid transparent;
        border-top-color: #333;
        transform: translateX(-50%);
        pointer-events: none;
    }
}

.statistics-blocklist__tooltip-text {
    display: block;
    margin-bottom: 0;
}
