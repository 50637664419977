.two-col {
    margin: -1rem;

    @include mq($from: lg) {
        align-items: center;
    }

    @include mq($from: md) {
        display: flex;
        margin: -2rem;
    }
}

.two-col--reversed {
    .two-col__item:first-child {
        order: 2;
    }
}

.two-col--column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.two-col__item {
    padding: 1rem;

    p:last-child {
        margin-bottom: 0;
    }

    a:not(.button) {
        color: $primary-color;
    }

    @include mq($from: md) {
        width: 50%;
        padding: 2rem;
    }
}

.two-col__item--center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.two-col__item--flat-mobile {
    padding: 0;

    @include mq($from: md) {
        padding: 1rem;
    }
}

.two-col__title {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 1.75rem !important;
    line-height: 1.5 !important;

    @include mq($from: lg) {
        padding-right: 2em;
    }

    .two-col__small-text {
        color: $primary-color;
    }

    .two-col__title-municipality {
        word-break: break-word;
    }
}

.two-col__small-text {
    font-size: 1rem;
}

.two-col__share {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    font-weight: 400;

    @include mq($from: sm) {
        display: none;
    }
}

.two-col__share-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;

    svg {
        width: 1.25rem;
        height: 1.25rem;
    }
}
