.product {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: wrap;
    padding: 1rem;
    border: 1px solid $line-color;
    border-radius: $border-radius;

    @include mq($from: sm) {
        padding: 2rem;
    }

    @include mq($from: lg) {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}

.product__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    @include mq($from: lg) {
        width: 50%;
    }
}

.product__heading {
    max-width: 100%;
}

.product__image-holder {
    position: relative;
    width: 100%;
    margin-bottom: 1rem;

    @include mq($from: lg) {
        width: 50%;
        margin-left: 1rem;
        margin-bottom: 0;
    }
}

.product__price {
    display: flex;
    margin-bottom: 0.5rem;
    color: $primary-color;
    font-size: 1.5rem;
}

.product__price-old {
    margin-right: 0.5rem;
    text-decoration: line-through;
    opacity: 0.5;
}

.product__button {
    margin-top: auto;
}

.product__image {
    display: block;
    max-height: 225px;
}
