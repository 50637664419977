.odometer-outer {
    position: relative;
    display: block;
    width: 100%;
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
    background: none;
    text-align: center;
    line-height: 0;

    .odometer {
        margin-bottom: -0.5em;
    }

    .odometer-b & {
        max-width: 240px;
        margin: 0 120px 0 0;

        @include mq($until: lg) {
            margin: 0 60px 0 0;
        }

        @include mq($until: md) {
            margin: 0 auto;
        }
    }
}

button.odometer-outer {
    & .odometer {
        cursor: help;
    }
}

.odometer-outer__tooltip {
    position: absolute;
    left: 50%;
    top: 100%;
    display: inline-block;
    padding: 0.4em 0.85em 0.5em;
    border-radius: $border-radius;
    transform: translate(-50%, 10px);
    background-color: $primary-color;
    background-image: $primary-gradient;
    color: #fff;
    font-size: 0.9rem;
    line-height: $base-line-height;
    opacity: 0;
    transition: all 0.15s;
    transition-property: transform, opacity;
    cursor: pointer;
    pointer-events: none;

    .odometer-b & {
        left: 0;
        transform: translate(35%, 10px);
    }

    &:hover,
    .odometer-outer:hover & {
        .odometer-b & {
            transform: translate(35%, 0);
        }
        transform: translate(-50%, 0);
        opacity: 1;
        pointer-events: auto;
    }

    &:before {
        content: '';
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -0.5em;
        border: 0.5em solid transparent;
        border-bottom-color: mix($secondary-color, $primary-color);
    }
}
