.locktips__holder {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: -20px;
    margin-bottom: 0;

    @include mq($from: md) {
        flex-direction: row;
    }
}

.locktip {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(50% - 20px);
    margin: 10px;
    border: 1px solid $line-color;
    border-radius: 5px;

    h2 {
        margin-bottom: 10px;
    }

    @include mq($from: md) {
        max-width: calc(50% - 20px);
    }
}

.locktip__content-wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 20px 30px;
}

.locktip__content {
    flex-grow: 1;
}

.locktip__header {
    flex-shrink: 0;
    border-radius: $border-radius $border-radius 0 0;
    overflow: hidden;

    img {
        width: 100%;
        max-height: 280px;
        object-fit: cover;
    }

    @include mq($from: md) {
        img {
            max-height: 280px;
        }
    }

    @include mq($from: lg) {
        img {
            max-height: 220px;
        }
    }
}

.locktip__score {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    span {
        margin: 0 5px 0 2px;
        color: $primary-color;
    }

    svg {
        height: 24px;
        fill: $primary-color;
    }
}

.locktip__button {
    padding: 6px 10px 6px 16px;
    border: 1px solid $primary-color;
    border-radius: 25px;
    font-size: 0.8rem;
    color: $primary-color;

    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        height: 1em;
    }

    &:hover,
    &:focus {
        fill: #fff;
        color: #fff;
        background-color: $primary-color;
        background-image: $primary-gradient;
    }
}
