.tips {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2rem 0;
    padding: 0;

    &:last-child {
        margin-bottom: -1rem;
    }
}

.tips--mini {
    flex-direction: row;
    flex-wrap: wrap;
    width: calc(100% + #{$content-whitespace});
    margin: 1rem (-$content-whitespace / 2);

    &:last-child {
        margin-bottom: -2rem;
    }
}

.tip {
    display: flex;
    padding: 1.25rem 0;
    border-top: 1px solid $line-color;
    font-size: 1rem;

    &:last-child {
        padding-bottom: 0;
    }

    .tips--mini & {
        position: relative;
        flex: 0 0 auto;
        flex-direction: column;
        width: 50%;
        padding: ($content-whitespace) ($content-whitespace / 2) ($content-whitespace / 2);
        margin: 0 0 1rem;
        border: none;

        &:before {
            content: '';
            position: absolute;
            top: $content-whitespace / 2;
            left: $content-whitespace / 2;
            right: $content-whitespace / 2;
            display: block;
            border-top: 1px solid $line-color;
        }
    }

    .modal__content & {
        font-size: 0.9rem;
    }
}

.tip__content {
    ul,
    ol {
        padding-left: 1.1em;
    }

    ul {
        list-style-type: disc;
    }
}

.tip__icon {
    display: block;
    flex: 1 0 auto;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0 1.25em 0 0;

    .tips--mini & {
        margin: 0.85em 0 0.5em;
    }
}

.tip__title {
    display: block;
    font-size: 1.25em;

    &,
    .app--responsive & {
        line-height: 1.5;
    }
}

.tip__subtitle {
    display: block;
    font-size: 1em;
    font-weight: 500;

    &,
    .app--responsive & {
        margin: 0 0 0.2em;
        line-height: 1.5;
    }

    .modal__content & {
        font-size: 0.9rem;
    }
}

.tip__description {
    display: block;
    margin-bottom: 1em;
    color: $text-color;

    &:last-child {
        margin-bottom: 0;
    }

    &,
    .app--responsive & {
        font-size: 0.9em;

        @include mq($from: md) {
            font-size: 0.875em;
        }
    }
}

.tips__disclaimer {
    font-size: 0.85rem;
}
