.header-postal-code {
    position: relative;
    display: none;
    width: 100%;
    margin-top: 1rem;

    @include mq($from: md) {
        display: flex;
        margin-top: 0;
        margin-left: 2rem;
        width: auto;
    }

    .nav-main-state:checked ~ .header & {
        display: flex;
    }

    &.has-error {
        .header-postal-code__label {
            color: $quaternary-color;
        }

        .header-postal-code__label-input-wrapper {
            border-color: $quaternary-color;
        }

        .header-postal-code__button {
            background-color: $quaternary-color;
            background-image: $secondary-gradient;
        }

        .header-postal-code__tooltip {
            opacity: 1;
            pointer-events: all;
        }
    }
}

.header-postal-code__label-input-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.75rem;
    border: 1px solid $line-color;
    border-radius: $border-radius 0 0 $border-radius;
}

.header-postal-code__label {
    font-size: 0.7rem;
    color: $primary-color;
}

.header-postal-code__input {
    width: 4rem;
    border: none;

    &:focus {
        outline: none;
    }

    &:invalid {
        color: $text-color;
    }

    &:valid {
        color: #000;
    }

    // Important that the placeholder comes last, otherwise it will be overwritten in IE11
    &::placeholder {
        color: mix(#fff, $text-color, 75%);
    }
}

.header-postal-code__button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.75rem;
    border: none;
    border-radius: 0 $border-radius $border-radius 0;
    outline: none;
    cursor: pointer;
    background-color: $primary-color;
    background-image: $primary-gradient;

    &:hover,
    &:focus {
        color: #fff;
        fill: #fff;
        background-color: $primary-color-lighter;
        background-image: $primary-gradient-lighter;
    }
}

.header-postal-code__icon {
    width: 1.25rem;
    height: 1.25rem;

    svg {
        fill: #fff;
        width: 1.25rem;
        height: 1.25rem;
    }
}

.header-postal-code__tooltip {
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    left: -10px;
    display: block;
    max-width: 200px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    transition: opacity 0.2s;
    background-color: $quaternary-color;
    background-image: $secondary-gradient;
    color: #fff;
    font-size: 0.65rem;
    text-align: left;
    opacity: 0;
    pointer-events: none;

    &:after {
        content: '';
        position: absolute;
        top: -15px;
        left: 25px;
        border: 0.5rem solid transparent;
        border-bottom-color: $quaternary-color;
        transform: translateX(-50%);
        pointer-events: none;
    }
}
