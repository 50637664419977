@import '../generic/animations';

$odometer-path-length: 875 !default;
$odometer-options-count: 10 !default;
$odometer-timing-function: cubic-bezier(0.87, 0.005, 0.485, 0.955) !default;
$odometer-states: (
    1: (
        color: 'green',
        value: 'very-low',
        label: 'Zeer laag'
    ),
    2: (
        color: 'green',
        value: 'very-low',
        label: 'Zeer laag'
    ),
    3: (
        color: 'green',
        value: 'low',
        label: 'Laag'
    ),
    4: (
        color: 'green',
        value: 'low',
        label: 'Laag'
    ),
    5: (
        color: 'orange',
        value: 'present',
        label: 'Middelmatig'
    ),
    6: (
        color: 'orange',
        value: 'present',
        label: 'Middelmatig'
    ),
    7: (
        color: 'red',
        value: 'increased',
        label: 'Hoog'
    ),
    8: (
        color: 'red',
        value: 'increased',
        label: 'Hoog'
    ),
    9: (
        color: 'red',
        value: 'high',
        label: 'Zeer hoog'
    ),
    10: (
        color: 'red',
        value: 'high',
        label: 'Zeer hoog'
    )
) !default;

.odometer {
    position: relative;
    display: block;
    width: 100%;
    padding-bottom: 100%;
    border-radius: 50%;
    box-shadow: 0 0 2rem rgba($text-color, 0.1);
    line-height: 0;
    font-style: normal;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 38%;
        height: 38%;
        margin-top: -19%;
        margin-left: -19%;
        border-radius: 50%;
        border: 0.25em solid transparent;
        border-top-color: mix($primary-color, $primary-color-lighter);
        opacity: 0;
        transition: opacity 0.5s;
        animation: spin 1s linear infinite;
        pointer-events: none;

        .odometer-b & {
            width: 63%;
            height: 63%;
            margin-top: -32%;
            margin-left: -32%;
        }
    }

    &.is-loading:after {
        opacity: 1;
    }

    > svg {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }

    html.no-svg-transforms & {
        &:before {
            position: absolute;
            z-index: 2;
            top: 0.1em;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            text-align: center;
            pointer-events: none;
            font-size: 1.5rem;
        }
    }
}

.odometer__gradient,
.odometer__pointer,
.odometer__value {
    opacity: 0;
    transition: opacity 0.2s;
}

.odometer__gradient,
.odometer__pointer:not(.odometer__pointer--unknown),
.odometer__value {
    .odometer.is-loading & {
        opacity: 0 !important;
        transition-duration: 0s !important;
    }
}

.odometer__value {
    pointer-events: none;
}

@each $value, $state in $odometer-states {
    .odometer[data-prediction='#{$value}'] {
        .odometer__value--#{map-get($state, 'value')},
        .odometer__gradient--#{map-get($state, 'color')} {
            opacity: 1;
        }

        html.svg-transforms & .odometer__pointer--#{map-get($state, 'color')} {
            opacity: 1;
        }

        html.no-svg-transforms & {
            &:before {
                content: map-get($state, 'label');
            }

            .odometer__pointers__fallback .odometer__pointer:nth-child(#{$value}) {
                opacity: 1;
            }
        }

        .odometer__value--#{map-get($state, 'value')} {
            pointer-events: auto;
        }
    }
}

.odometer[data-prediction='unknown'] .odometer__value--unknown {
    opacity: 1;
}

.odometer[data-prediction='unknown'] {
    html.no-svg-transforms &:before {
        content: 'Onbekend';
    }
}

.odometer__pointers {
    html.svg-transforms & {
        transform-origin: center;
        transition-property: transform;
        transition-timing-function: $odometer-timing-function;
        transition-duration: 0.2s;
    }

    @for $i from 1 through $odometer-options-count {
        .odometer[data-prediction='#{$i}'] & {
            html.svg-transforms & {
                transform: rotateZ(22.5deg + (($i - 1) * 30deg));
                transition-duration: 0.4s + ($i * 0.1s);
            }
        }
    }

    .odometer.is-loading & {
        transform: rotateZ(0deg) !important;
        transition-duration: 0s !important;
    }
}

.odometer__pointer--unknown {
    .odometer[data-prediction=''] &,
    .odometer[data-prediction='unknown'] & {
        opacity: 1;
    }
}

.odometer__pointers__fallback {
    html.svg-transforms & {
        display: none;
    }
}

.odometer__gradient {
    stroke-dasharray: $odometer-path-length;
    stroke-dashoffset: $odometer-path-length;

    html.svg-transforms & {
        animation-timing-function: $odometer-timing-function;
        animation-fill-mode: forwards;
    }

    @for $i from 1 through $odometer-options-count {
        .odometer[data-prediction='#{$i}'] & {
            html.svg-transforms & {
                animation-name: fill-#{$i};
                animation-duration: 0.4s + ($i * 0.1s);
            }

            html.no-svg-transforms & {
                stroke-dashoffset: ($odometer-options-count - $i) * ($odometer-path-length / $odometer-options-count);
            }
        }

        @keyframes fill-#{$i} {
            to {
                stroke-dashoffset: ($odometer-options-count - $i) * ($odometer-path-length / $odometer-options-count);
            }
        }
    }
}

.odometer__label {
    fill: $text-color-darker;
}
