.leaflet-container {
    width: 100%;
    padding-bottom: 100%;
    z-index: 0;
    border-radius: $border-radius;

    @include mq($from: sm) {
        padding-bottom: percentage(9/16);
    }

    @include mq($from: lg) {
        padding-bottom: 100%;
    }
}


a.leaflet-popup-close-button {
    .leaflet-container & {
        top: 5px;
        right: 5px;
    }
}

.leaflet-popup-content {
    min-width: 270px;
    margin: 0;
}

.district-marker {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    flex-grow: 0;
    width: 2rem !important;
    height: 2rem !important;
    border-radius: 50%;
    background-image: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%));
    color: #fff;
}

.district-marker--small {
    width: 1.5rem !important;
    height: 1.5rem !important;
}

.district-marker__risk1 {
    background-image: linear-gradient(-45deg, #1ad18e, #1ad0cc);
}

.district-marker__risk2 {
    background-image: linear-gradient(-45deg, #1ad18e, #1ad0cc);
}

.district-marker__risk3 {
    background-image: linear-gradient(-45deg, #1ad18e, #1ad0cc);
}

.district-marker__risk4 {
    background-image: linear-gradient(-45deg, #1ad18e, #1ad0cc);
}

.district-marker__risk5 {
    background-image: linear-gradient(-45deg, #f87533, #ffbd44);
}

.district-marker__risk6 {
    background-image: linear-gradient(-45deg, #f87533, #ffbd44);
}

.district-marker__risk7 {
    background-image: linear-gradient(-45deg, #ff4773, #ff47b0);
}

.district-marker__risk8 {
    background-image: linear-gradient(-45deg, #ff4773, #ff47b0);
}

.district-marker__risk9 {
    background-image: linear-gradient(-45deg, #ff4773, #ff47b0);
}

.district-marker__risk10 {
    background-image: linear-gradient(-45deg, #ff4773, #ff47b0);
}
