html {
    box-sizing: border-box;
    height: 100%;
    overflow-y: scroll;
    overflow-y: overlay;
    overflow-x: hidden;
    background-color: $primary-background-color;

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    &.modal-open {
        overflow: hidden;
    }
}

body {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    flex: 1 0 auto;
    max-width: 100%;
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

.sprite {
    display: inline-block;
    vertical-align: middle;
    width: 1em;
    height: 1em;
}

.container {
    width: map-get($breakpoints, $content-breakpoint);
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: $content-whitespace;
    padding-right: $content-whitespace;

    @include mq($from: $content-breakpoint) {
        max-width: 90%;
    }
}

.container--small {
    width: 700px;
}

.container--wide {
    width: 100%;
}
