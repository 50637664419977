.filters-intro {
    margin-bottom: 0.5rem;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.filters__text {
    margin-right: 0.25rem;
    font-size: 1rem;
    color: #000;

    @include mq($from: md) {
        font-size: 1.5rem;
    }
}

.filters .filters-select {
    max-width: 100%;
    margin-right: 0.25rem;
}

.filters .filters-select__control {
    border: 0;
    border-radius: 0;
    border-bottom: 2px solid $primary-color;

    &:hover,
    &:focus,
    &.filters-select__control--is-focused,
    &.filters-select__menu-is-open {
        border: 0;
        border-bottom: 2px solid mix(#000, $primary-color, 10%);;
        outline: 0 !important;
        box-shadow: none;
    }
}

.filters .filters-select__value-container {
    display: flex;
    flex-basis: auto;
    align-items: center;
    position: static;
    font-size: 1rem;
    overflow: visible;

    @include mq($from: md) {
        font-size: 1.5rem;
    }
}

.filters .filters-select__single-value,
.filters .filters-select__placeholder {
    position: static;
    display: flex;
    align-items: center;
    overflow: visible;
    transform: translateY(0px);
}

.filters .filters-select__single-value {
    color: $primary-color;
}

.filters .filters-select__indicator-separator {
    display: none;
}

.filters .filters-select__indicator,
.filters .filters-select__dropdown-indicator {
    color: $primary-color;

    &:hover,
    &:focus {
        color: mix(#000, $primary-color, 10%);
    }
}

.filters .filters-select__menu {
    z-index: 3;
}

.filters .filters-select__option {
    &:hover,
    &:focus,
    &.filters-select__option--is-focused {
        background-color: $primary-color-lighter;
    }

    &.filters-select__option--is-selected {
        background-color: $primary-color;
    }
}
