.bottom-bar {
    position: fixed;
    z-index: $z-index-bottom-bar;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 0.75em 0;
    transform: translateY(100%);
    background-color: $primary-background-color;
    box-shadow: 0 -5px 40px rgba($text-color, 0.05), 0 -2px 4px rgba($text-color, 0.025);
    opacity: 0;
    transition: transform 0.2s, opacity 0s 0.2s;

    @include mq($from: sm) {
        padding: 1.25em 0;
    }

    &.is-active {
        transform: translateY(0);
        opacity: 1;
        transition: transform 0.2s;
    }
}

.bottom-bar__content {
    @include mq($from: md) {
        display: flex;
        align-items: center;
    }
}

.bottom-bar__note {
    display: inline-block;
    margin-right: auto;
    margin-bottom: 0.75em;
    padding-right: 1em;
    font-size: 0.9em;
    color: $text-color;

    a {
        color: $primary-color;
    }

    @include mq($from: md) {
        margin-bottom: 0;
        padding-right: 2em;
    }
}

.bottom-bar__button {
    flex-shrink: 0;
    flex-grow: 0;
    margin: 0;
}
