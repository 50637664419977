.section {
    padding: 4rem 0;

    @include mq($until: md) {
        padding: 3rem 0;
    }

    & + & {
        padding-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }
}

.section--odometer {
    padding: 2rem 0 4rem;

    @include mq($until: md) {
        padding: 2rem 0 3rem;
    }
}

.section--small {
    padding: 1rem 0 1rem;
}
