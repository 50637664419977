.result-more-info {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: calc(100% + 2rem);
    margin-right: -2rem;
    margin-bottom: -2rem;

    @include mq($from: md) {
        flex-wrap: nowrap;
    }
}

.result-more-info__item {
    flex-shrink: 0;
    width: calc(100% - 2rem);
    margin-right: 2rem;
    margin-bottom: 2rem;
    padding: 1.5rem;
    border: 1px solid $line-color;
    border-radius: $border-radius;

    p {
        margin-bottom: 0.75rem;
        font-size: 0.9rem;
        color: $text-color-lighter;
    }

    @include mq($from: md) {
        width: calc(50% - 2rem);
    }
}

.result-more-info__heading--barchart {
    margin-bottom: 1rem;
}
