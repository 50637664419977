.statistics-list {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-bottom: 0;
    padding-left: 0;
    border-radius: 3px;
    list-style: none;
    background-color: #fff;

    @include mq($from: md) {
        flex-wrap: nowrap;
        width: calc(100% + 1rem);
    }
}

.statistics-list__item {
    position: relative;
    display: flex;
    padding: 0.5rem 2rem 0.5rem 0;
}

.statistics-list__icon {
    display: block;
    flex-shrink: 0;
    width: 1.5rem;
    height: 1.5rem;
    margin-top: 1px;
    margin-right: 0.75rem;
    fill: $text-color-lighter;
}

.statistics-list__text {
    color: #000;
}

.statistics-list__help {
    position: absolute;
    top: calc(0.5rem + 3px);
    right: 0;
    display: block;
    width: 200px;
    height: 1.5rem;
}

.statistics-list__help-icon {
    position: absolute;
    right: 0;
    display: block;
    width: 1.25rem;
    height: 1.25rem;
    fill: $primary-color;
}

.statistics-list__tooltip {
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 0.2rem);
    right: -0.65rem;
    display: block;
    max-width: 200px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    transition: opacity 0.2s;
    background-color: #333;
    color: #fff;
    font-size: 0.65rem;
    text-align: left;
    opacity: 0;
    pointer-events: none;

    .statistics-list__help-icon:hover + &,
    .statistics-list__help-icon:focus + & {
        opacity: 1;
        pointer-events: all;
    }

    &:after {
        content: '';
        position: absolute;
        top: calc(100% - 1px);
        right: 5px;
        border: 0.5rem solid transparent;
        border-top-color: #333;
        transform: translateX(-50%);
        pointer-events: none;
    }
}

.statistics-list__tooltip-text {
    display: block;
    margin-bottom: 0;
}
