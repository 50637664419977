.cta--predict {
    padding: 5em 0;
    background-color: $primary-color-lighter;

    h2 {
        margin-bottom: 1.25rem;
        font-size: 1.75rem;
    }

    .cta__text {
        font-size: 0.8rem;
        color: $text-color-lighter;
    }
}

.cta__content {
    .cta--predict & {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq($from: sm) {
            max-width: percentage(7/12);
        }

        @include mq($from: md) {
            max-width: percentage(5/12);
        }
    }
}
