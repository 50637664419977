.hero {
    display: flex;
    align-items: flex-end;
    min-height: 300px;
    padding: 3rem 0 0.8rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @include mq($from: sm) {
        min-height: 450px;
        padding: 3rem 0;
    }
}

h1.hero__heading {
    width: 500px;
    max-width: 100%;
    color: #fff;
    font-size: 2rem;

    @include mq($from: sm) {
        font-size: 2.25rem;
    }
}
