.icon-and-list {
    display: flex;
    margin-top: 1rem;
}

.icon-and-list__icon {
    width: 4rem;
    height: 4rem;
    margin-top: 4px;
    margin-right: 1rem;
    fill: #000;
}

.icon-and-list__list {
    p {
        margin-bottom: 0.5rem;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        display: flex;
        margin-bottom: 0.3rem;
        color: $text-color;
        font-size: 0.9rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

h3.icon-and-list__heading {
    margin: 0 0 0.5rem;
    font-size: 1rem;
    font-weight: 400;
}

.icon-and-list__list-icon {
    flex-shrink: 0;
    width: 0.9rem;
    height: 0.9rem;
    margin-top: 7px;
    margin-right: 0.5rem;
    fill: $primary-color;
}
