.product-review {
    display: flex;
    align-items: center;
}

.product-review__stars {
    padding: 0;
    margin: 0 0.5rem 0 0;
    list-style: none;
}

.product-review__star {
    display: inline-block;
    margin-right: 0.2rem;
    color: #f1be00;

    &:last-child {
        margin-right: 0;
    }
}

.product-review__number {
    font-size: 0.8rem;
}
