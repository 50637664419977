.risk-alert-banner {
    padding: 1.5rem;
    border-radius: $border-radius;
    background-color: $secondary-background-color;

    p {
        margin-bottom: 0.75rem;
        font-size: 0.8rem;
    }

    button {
        margin-bottom: 0;
    }
}

.risk-alert-banner__heading-holder {
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
}

.risk-alert-banner__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.5rem;
    animation: ringAnimation ease-in-out 1s;
    animation-iteration-count: 1;
    transform-origin: 50% 0;
    animation-delay: 3s;
    fill: #000;
}

.risk-alert-banner__heading {
    margin-bottom: 0;
}

@keyframes ringAnimation {
    0% {
        transform: rotate(0deg);
    }

    10% {
        transform: rotate(10deg);
    }

    20% {
        transform: rotate(-10deg);
    }

    30% {
        transform: rotate(10deg);
    }

    40% {
        transform: rotate(-10deg);
    }

    50% {
        transform: rotate(10deg);
    }

    60% {
        transform: rotate(-10deg);
    }

    70% {
        transform: rotate(10deg);
    }

    80% {
        transform: rotate(-10deg);
    }

    90% {
        transform: rotate(10deg);
    }

    100% {
        transform: rotate(0deg);
    }
}
