$intro-breakpoint: md !default;
$block-whitespace: 1rem;

.intro {
    position: relative;
    z-index: 1;
    width: 100%;
    margin-bottom: 1em;

    @include mq($from: $intro-breakpoint) {
        display: flex;
        align-items: center;
        min-height: 65vh;
        margin-bottom: 0;
    }

    .block__seperator {
        @include mq($until: $intro-breakpoint) {
            height: 0;
            margin: 0.75em 0;
        }
    }
}

.intro--burglary {
    @include mq($until: md) {
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 0;
    }
}

.intro--results {
    padding-top: 1.25em;

    @include mq($from: $intro-breakpoint) {
        min-height: 0;
        padding: 10vh 0 7.5vh;
    }
}

.intro__image {
    padding-bottom: 50%;
    margin-bottom: 1.5em;
    background-color: $secondary-background-color;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .intro--burglary & {
        @include mq($until: md) {
            margin-bottom: 0;
        }
    }

    @include mq($from: $intro-breakpoint) {
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-bottom: 0;
        margin-bottom: 0;
    }
}

.intro__postalcode-block {
    display: block;
    width: 100%;
    background-color: #fff;
    border-radius: $border-radius;

    a:not(.button) {
        color: $primary-color;
    }

    @include mq($until: $intro-breakpoint) {
        padding: 0 0 2em;
        border-bottom: 1px solid $line-color;
        border-radius: 0;
    }

    @include mq($from: $intro-breakpoint) {
        width: 480px;
        max-width: 100%;
        margin: 10vh 0;
        padding: $block-whitespace * 1.5;
    }

    .block__seperator {
        @include mq($from: $intro-breakpoint) {
            margin-left: -$block-whitespace * 1.5;
            margin-right: -$block-whitespace * 1.5;
        }
    }

    .intro--burglary & {
        @include mq($until: md) {
            padding-top: 3rem;
        }
    }
}

.intro__content--results {
    h2 {
        margin-bottom: 0.1em;
        text-align: center;

        & + .note {
            display: block;
            font-size: 1rem;
            text-align: center;
        }

        strong {
            font-weight: normal;
        }
    }

    &.has-results h2 strong {
        @extend .text-gradient;
        @extend .text-gradient--secondary;
    }

    .button {
        margin-right: 1em;
    }
}
