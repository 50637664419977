.rte-content {
    h1 {
        font-size: 1.75em;
        font-weight: 400;

        @include mq($from: sm) {
            font-size: 2em;
        }
    }

    h2 {
        font-size: 1.5rem;
        font-weight: 400;
    }

    h3 {
        font-weight: 400;
    }
}
