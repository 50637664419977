.burglary-alert {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    min-height: 1%;
    padding: $content-whitespace * 4;
    padding-bottom: 0;
    border-radius: $border-radius;
    overflow: hidden;
    background-color: $primary-color-light;

    @include mq($until: md) {
        padding: $content-whitespace;
    }
}

.burglary-alert__content {
    width: 50%;
    margin-bottom: $content-whitespace * 3;

    @include mq($until: md) {
        width: 100%;
        margin-bottom: $content-whitespace;
    }

    h2 {
        font-size: 32px;
        font-weight: 400;

        .risk-alert-banner__icon {
            vertical-align: middle;
        }
    }

    p {
        color: $text-color-darker;
        opacity: 0.9;
    }
}

.burglary-alert__image {
    width: 35%;
    min-height: 1%;
    margin-top: -$content-whitespace * 4;

    img {
        display: block;
        max-width: 100%;
        max-height: 300px;
    }

    @include mq($until: md) {
        display: none;
    }
}
