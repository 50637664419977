$barchart-states: (
    1: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%)),
        height: 10%,
        text-color: $text-color,
        number-top: -1.5rem,
    ),
    2: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%)),
        height: 10%,
        text-color: $text-color,
        number-top: -1.5rem,
    ),
    3: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%)),
        height: 30%,
        text-color: #fff,
        number-top: 0,
    ),
    4: (
        color: linear-gradient(-45deg, mix(#fff, #00cc81, 10%), mix(#fff, #00cbc6, 10%)),
        height: 40%,
        text-color: #fff,
        number-top: 0,
    ),
    5: (
        color: linear-gradient(-45deg, mix(#fff, #f7661c, 10%), mix(#fff, #ffb62f, 10%)),
        height: 50%,
        text-color: #fff,
        number-top: 0,
    ),
    6: (
        color: linear-gradient(-45deg, mix(#fff, #f7661c, 10%), mix(#fff, #ffb62f, 10%)),
        height: 60%,
        text-color: #fff,
        number-top: 0,
    ),
    7: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%)),
        height: 70%,
        text-color: #fff,
        number-top: 0,
    ),
    8: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%)),
        height: 80%,
        text-color: #fff,
        number-top: 0,
    ),
    9: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%)),
        height: 90%,
        text-color: #fff,
        number-top: 0,
    ),
    10: (
        color: linear-gradient(-45deg, mix(#fff, #ff3363, 10%), mix(#fff, #ff33a7, 10%)),
        height: 100%,
        text-color: #fff,
        number-top: 0,
    )
) !default;

$barchart-banner-breakpoint: lg;

.barchart-banner {
    border: 1px solid $line-color;
    border-radius: $border-radius;

    @include mq($from: $barchart-banner-breakpoint) {
        display: flex;
    }

    > * {
        padding: 2rem 1.5rem;
    }
}

.barchart-banner__content {
    flex: 1 0 auto;
    max-width: 100%;
}

.barchart-banner__sign-up {
    border-top: 1px solid $line-color;

    @include mq($from: $barchart-banner-breakpoint) {
        flex: none;
        width: percentage(4/12);
        border-top: none;
        border-left: 1px solid $line-color;
    }
}

.barchart {
    display: flex;
    max-width: 750px;
    border-radius: 4px;
}

.barchart--municipality {
    margin-top: 1rem;
    max-width: none;
    overflow-x: auto;
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-left: -20px;

    &:after {
        content: '--';
        display: block;
        width: 20px;
        height: 20px;
        color: transparent;
        opacity: 0;
    }

    @include mq($from: lg) {
        overflow-x: auto;
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        &:after {
            display: none;
        }
    }
}

.barchart__bar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: percentage(1/11);
    padding: 0.1rem;
    border-radius: 4px;

    &.barchart__bar--highlighted {
        width: auto;
        min-width: percentage(1/11);
        background-color: #e0f7f6;
    }

    .barchart--municipality & {
        min-width: 2.25rem;
        width: percentage(1/24);

        &:first-child {
            margin-left: 20px;
        }

        &:last-child {
            margin-right: 20px;
        }
    }

    @include mq($from: sm) {
        padding: 0.2rem;
    }

    @include mq($from: lg) {
        .barchart--municipality & {
            min-width: 1.75rem;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.barchart__bar-fill-wrap {
    display: flex;
    align-items: flex-end;
    width: 100%;
    max-width: 2rem;
    height: 200px;
    margin-bottom: 0.5rem;

    .barchart--municipality & {
        height: 100px;
    }
}

.barchart__bar-fill {
    position: relative;
    flex: 1 1 0%;
    border-radius: 4px;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        border-radius: $border-radius;

        .barchart__bar--passed-date & {
            opacity: 0.5;
        }
    }

    .barchart.barchart--municipality & {
        &:after {
            background-image: none !important;
            background-color: $primary-color;
        }
    }
}

.barchart__bar-number {
    position: absolute;
    z-index: 1;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.7rem;
    color: #fff;
}

.barchart__tooltip {
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 0.2rem);
    left: 50%;
    display: block;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    transform: translateX(-50%);
    transition: opacity 0.2s;
    background-color: #333;
    color: #fff;
    font-size: 0.65rem;
    text-align: center;
    opacity: 0;

    .barchart__bar-fill:focus &,
    .barchart__bar-fill:hover & {
        opacity: 1;
    }

    &:after {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        border: 0.5rem solid transparent;
        border-top-color: #333;
        transform: translateX(-50%);
    }
}

.barchart__tooltip-title {
    display: block;
    margin-bottom: 0;
    white-space: nowrap;
}

.barchart__tooltip-text {
    white-space: nowrap;
}

.barchart__label {
    font-size: 0.8rem;

    .barchart__bar--inactive & {
        color: #c5c5c5;
    }

    .barchart__bar--passed-date & {
        opacity: 0.5;
    }
}

.barchart__date {
    display: none;
    font-size: 0.7rem;
    color: #c5c5c5;

    .barchart__bar--passed-date & {
        opacity: 0.5;
    }

    @include mq($from: sm) {
        display: block;
    }
}

@each $value, $state in $barchart-states {
    .barchart__bar[data-prediction='#{$value}'] {
        .barchart__bar-fill {
            height: map-get($state, 'height');

            &:after {
                background-image: map-get($state, 'color');
            }
        }

        .barchart__bar-number {
            top: map-get($state, 'number-top');
            color: map-get($state, 'text-color')
        }

    }
}
