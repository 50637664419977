.prediction-form {
    position: relative;
    display: flex;
    align-items: stretch;
    height: 60px;
    margin-bottom: 0.85em;

    @include mq($from: md) {
        height: 72px;
    }

    input[type='number'] {
        display: block;
        width: 110px;
        margin: 0;
        padding: 0.5em 0.25em;
        border: none;
        border-radius: $border-radius 0 0 $border-radius;
        background-color: $primary-color-lighter;
        font-size: 1.8rem;
        text-align: center;
        line-height: 0;
        -webkit-appearance: none;
        -moz-appearance: textfield;

        @include mq($from: md) {
            width: 140px;
            font-size: 2.1rem;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }

        &:focus {
            outline: none;
        }
    }

    &.has-error {
        .button {
            background-color: $quaternary-color;
            background-image: $secondary-gradient;
        }

        input[type='number'] {
            background-color: mix(#fff, $quaternary-color, 90);
        }
    }

    .button {
        display: flex;
        align-items: center;
        margin: 0;
        padding-left: 1.25em;
        padding-right: 1.25em;
        border-radius: 0 $border-radius $border-radius 0;
    }

    .button--disabled {
        background-image: linear-gradient(-45deg, mix($primary-color-lighter, $secondary-color, 70%), mix($primary-color-lighter, $primary-color, 70%));
        opacity: 1;
    }

    .button__icon {
        width: 1.5em;
        height: 1.5em;
        margin: 0;

        @include mq($from: md) {
            width: 1.75em;
            height: 1.75em;
        }
    }
}

.prediction-form--light {
    input[type='number'] {
        background-color: #fff;
    }
}

.prediction-form__label {
    display: block;
    width: 100%;
    margin-bottom: 0.5rem;
    color: #000;
}

.prediction-form__help {
    display: block;
    font-size: 0.8rem;
    color: $text-color-lighter;
    cursor: pointer;
}

.prediction-form__tooltip {
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    left: 20px;
    display: block;
    max-width: 200px;
    padding: 0.2rem 0.5rem;
    border-radius: 4px;
    transition: opacity 0.2s;
    background-color: $quaternary-color;
    background-image: $secondary-gradient;
    color: #fff;
    font-size: 0.65rem;
    text-align: left;
    opacity: 1;

    &:after {
        content: '';
        position: absolute;
        top: -15px;
        left: 25px;
        border: 0.5rem solid transparent;
        border-bottom-color: $quaternary-color;
        transform: translateX(-50%);
        pointer-events: none;
    }
}
