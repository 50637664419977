$low: #00cbc6;
$medium: #ffb62f;
$high: #ff33a7;

$predictionLevelColors : (
    1:$low,
    2:$low,
    3:$low,
    4:$low,
    5:$medium,
    6:$medium,
    7:$high,
    8:$high,
    9:$high,
    10:$high,
) !default;

.statistics-list--b {
    margin: 0;
    padding: 0;
    list-style-type: none;

    @each $i, $color in $predictionLevelColors {
        &[data-prediction='#{$i}'] {
            .statistics-list__highlighted-text {
                color: $color;
            }
        }
    }

    .statistics-list__highlighted-text {
        margin-right: 5px;
    }
}

.statistics-list__item--b {
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid $line-color;
    font-size: 16px;
}

.statistics-list__content--b {
    position: relative;
    display: flex;

    .statistics-list__text--b {
        flex-grow: 1;
    }

    .statistics-list__help {
        position: relative;
        top: 3px;
    }
}

.statistics-list__item--b:first-of-type {
    border-top: 1px solid $line-color;
}