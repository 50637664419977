.section--center {
    text-align: left;

    @include mq($from: md) {
        text-align: center;
    }
}

.section__content {
    display: block;
    width: 100%;
    padding: 4rem 0;

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child:not(.article-holder) {
        margin-bottom: 0;
    }

    .section.section--small & {
        max-width: 680px;
        margin-left: auto;
        margin-right: auto;
    }

    .section.section--medium & {
        padding-left: 5vw;
        padding-right: 5vw;
    }
}

.section__content--home-intro {
    padding-bottom: 2.5rem;

    @include mq($from: md) {
        padding-bottom: 3.5rem;
    }
}

.section__content--flat-top-mobile {
    padding-top: 0;

    @include mq($from: md) {
        padding-top: 4rem;
    }
}

.section__content--medium {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;

    @include mq($from: md) {
        padding-top: 3.5rem;
        padding-bottom: 3.5rem;
    }
}

.section__content--medium-top {
    padding-top: 2.5rem;

    @include mq($from: md) {
        padding-top: 3.5rem;
    }
}

.section__intro {
    margin-bottom: 2rem;

    @include mq($from: md) {
        width: 70%;
        margin-bottom: 3rem;
    }
}

.section__title {
    display: flex;
    align-items: center;
    margin-bottom: 1.25em;
    font-size: 1.25em;

    @include mq($from: md) {
        margin-bottom: 1.5em;
    }

    .section.section--center & {
        justify-content: flex-start;
        margin-bottom: 0.5em;

        @include mq($from: md) {
            justify-content: center;
            max-width: 75%;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
    }

    .section.section--faq & {
        @include mq($from: md) {
            margin-bottom: 1.5em;
        }
    }

    .section__intro & {
        margin-bottom: 0.5em;
    }

    .section__content.section__content--home-intro & {
        margin-bottom: 0;
        font-size: 2.5em;
    }
}

.section__title--large {
    font-size: 1.5em;
}

.section__title--extra-large {
    font-size: 2em;
}
