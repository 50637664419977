@import 'label';

.form {
    display: block;
}

.form__label {
    @extend .label;
}

.form__label--small {
    font-size: 0.9rem;
}

.form__label--large {
    font-size: 1.1em;
}

.form__field,
.form-field {
    display: block;
    width: 100%;
    padding: 0.9em 1em;
    margin-bottom: 0.75em;
    border: 1px solid $line-color;
    border-radius: $border-radius;
    outline: none;
    background-color: $secondary-background-color;
    box-shadow: none;
    -webkit-appearance: none;
}

.form__help {
    display: block;
    margin-bottom: 1.5em;
    font-size: 0.9rem;
    color: $text-color-lighter;
}

.form__footer {
    button {
        margin-bottom: 20px;
    }

    em {
        display: block;
        font-size: 12px;
        line-height: 1.4em;
        color: $text-color-lighter;
        text-align: center;
    }
}
