.prevention-tip-detail-cta {
    padding: 2rem;
    border-radius: $border-radius;
    background-color: $primary-color-lighter;
}

.prevention-tip-detail-cta__button {
    display: inline-flex;
    align-items: center;

    .button__icon {
        position: relative;
        top: 1px;
    }
}
