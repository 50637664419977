.footer {
    margin-top: 2em;
    font-size: 0.9em;

    @include mq($from: md) {
        margin-top: 5vh;
    }
}

.footer--flatten {
    margin-top: -1px;
}

.footer__content {
    display: flex;
    padding: 1.25em 0;

    @include mq($from: md) {
        padding: 2em 0;
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.footer__text {
    flex-grow: 1;
}

.footer__menu {
    a {
        margin-left: 2rem;
    }
}
