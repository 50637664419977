.locktool__header--result {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
}

.locktool__result-summary {
    display: flex;
    align-items: center;
    margin: 0 auto 40px;
    padding: 40px;
    border-radius: 5px;
    text-align: center;
    background-color: rgba($text-color, 0.075);

    @include mq($until: lg) {
        flex-direction: column;
        max-width: 400px;
    }

    .shield-container {
        position: relative;
        flex-grow: 1;
        width: 110px;
        margin-bottom: 20px;
    }

    .shield-container__score {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #fff;
        line-height: 1;
    }

    .shield-container__score-dynamic {
        display: block;
        font-size: 42px;
        font-weight: 700;
    }

    svg {
        max-width: 110px;

        path {
            display: none;
        }
    }

    .locktool__result-summary-title {
        margin-bottom: 0;
    }
}

.locktool__result-content {
    max-width: 800px;
    margin: 0 auto;
}

.locktool__result-summary--critical {
    span {
        color: $quaternary-color;
    }

    svg {
        .shield-tool_svg__critical {
            display: block;
        }
    }
}

.locktool__result-summary--normal {
    span {
        color: $tertiary-color;
    }

    svg {
        .shield-tool_svg__normal {
            display: block;
        }
    }
}

.locktool__result-summary--good {
    span {
        color: $secondary-color;
    }

    svg {
        .shield-tool_svg__good {
            display: block;
        }
    }
}

.locktool__result-content {
    h2 {
        margin-bottom: 20px;
    }
}
