.prevention-tip-stats-wrapper {
    margin-bottom: 2rem;

    @include mq($from: md) {
        margin-bottom: 4rem;
    }
}

.prevention-tip-stats {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 2rem);
    margin-right: -2rem;
    margin-bottom: 1rem;

    @include mq($from: md) {
        flex-wrap: nowrap;
        margin-bottom: 0;
    }
}

.prevention-tip-stats__item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    width: 100%;
    margin-right: 2rem;
    margin-bottom: 0.75rem;

    @include mq($from: md) {
        flex-direction: column;
        align-items: flex-start;
        width: auto;
        margin-bottom: 0;
    }
}

.prevention-tip-stats__item--price {
    margin-bottom: 0.5rem;
}

.prevention-tip-stats__label {
    min-width: 5rem;
    margin-right: 0.5rem;
    font-size: 0.8rem;
    font-weight: 700;
    color: $text-color-lighter;

    @include mq($from: md) {
        margin-bottom: 0.5rem;
        margin-right: 0;
        min-width: auto;
    }
}

.prevention-tip-stats__value {
    display: flex;
}

.prevention-tip-stats__price,
.prevention-tip-stats__price-discount {
    font-size: 1.5rem;

    @include mq($from: md) {
        font-size: 2rem;
        margin-top: -15px;
    }
}

.prevention-tip-stats__price {
    color: $primary-color;
}

.prevention-tip-stats__price-discount {
    margin-right: 0.5rem;
    text-decoration: line-through;
    color: $text-color-lighter;
}

.prevention-tip-stats__icon {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;

    svg {
        width: 1.5rem;
        height: 1.5rem;
        fill: #dbf5f5;
    }

    &:last-child {
        margin-right: 0;
    }
}

.prevention-tip-stats__icon--filled {
    svg {
        fill: $primary-color;
    }
}
