.intro--locktool {
    min-height: calc(100% - 74px);
    margin-bottom: 0px;
}

.locktool {
    // content-whitespace is added because of a padding on the route wrapper
    min-height: calc(100% - 74px);
    margin-bottom: 0px;
}

.locktool__header {
    max-width: 600px;
    margin-top: 20px;

    .button {
        margin-bottom: 10px;
    }

    em {
        line-height: 1;
    }
}

.locktool__content {
    margin-top: 20px;
}

.locktool__footer {
    padding: 30px 0;

    @include mq($until: md) {
        padding: 0 0 30px;
    }
}

.locktool__footer-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    > * {
        margin: 0;
        margin-left: 20px;

        @include mq($until: md) {
            padding: 0.6em 1.5em;
            font-size: 1rem;
        }
    }

    > :disabled {
        opacity: 0.3;
    }
}

.locktool__title {
    margin-bottom: 10px;
    line-height: 1.2;
}

.locktool__count {
    display: inline-block;
    margin: 0 0 10px;
    padding: 2px 10px;
    border-radius: 20px;
    background-color: $primary-color-light;
    font-size: 0.75rem;
}

.question-group {
    display: flex;
    flex-direction: column;
    margin: 30px -10px;

    input {
        display: none;
    }

    @include mq($from: md) {
        flex-direction: row;
    }
}

.question__wrapper {
    flex: 1 1 0;
    margin: 10px;
}

.question {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
    padding: 20px;
    border: 1px solid $line-color;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    background-color: rgba($text-color, 0.075);

    input[type='radio']:checked + & {
        border-color: $primary-color;
        background-color: $primary-color-light;
    }
}

.question-label__title {
    color: #000;
    font-size: 1.25rem;
    line-height: 1.2;
}

.question-label__image {
    margin-top: 20px;

    img {
        display: block;
    }
}

.locktool__more-info {
    a {
        color: $primary-color;
    }
}
